module.exports = [{
  plugin: require('/tmp/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
  options: {
    "plugins": [],
    "id": "GTM-W76NMC"
  }
}, {
  plugin: require('/tmp/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];